import {post,get} from '../utils/axios'

// 收入视图
export function getCommissionView(params) {
  return post('commission/getView', params)
}
// 收入金额
export function getCommissionAmount(params) {
  return post('commission/getData', params)
}

// 收入明细
export function getCommissionDetail(params) {
  return post('commission/getDetail', params)
}

// 收入下拉选项
export function getCommissionOptions(params) {
  return post('commission/getOptions', params)
}