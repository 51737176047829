<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="picye">
        <p class="picye_title">
          <span>{{ commissionAll.name }}</span>
          <van-popover theme="dark" class="popover" v-model:show="commissionAll.showTip">
            <div class="tips">{{commissionAll.tips}}</div>
            <template #reference>
              <van-icon class="wen" name="question-o" @click.stop="toggleTips(1)" />
            </template>
          </van-popover>
        </p>
        <p class="picye_money">
          <span>{{ balanceInfo.allCommission }}</span>
        </p>
        <div class="picye_btn">
          <div @click="toPage(item.url)" v-for="(item,index) in commissionSub" :key="index">
            <span>{{item.name}}</span>
            <span>{{balanceInfo[item.key]}}</span>
          </div>
        </div>
      </div>
      <van-cell-group title="" class="card-box">
        <van-cell center  is-link v-for="(item, index) in commissionList" :key="index" @click="toPage(item.url)">
          <template #title>
            <span class="custom-title">{{ item.name }}</span>
            <van-popover theme="dark" class="popover" v-model:show="item.showTip">
              <div class="tips">{{item.tips}}</div>
              <template #reference>
                <van-icon class="wen" name="question-o" @click.stop="toggleTips(2,index)" />
              </template>
            </van-popover>
          </template>
          <div>¥ {{ balanceInfo[item.key] }}</div>
        </van-cell>
      </van-cell-group>

      <van-cell-group title="" class="card-box">
        <van-cell center  is-link v-for="(item, index) in balance" :key="index" @click="toPage('/wallet')">
          <template #title>
            <span class="custom-title">{{ item.name }}</span>
          </template>
          <span>¥ {{ balanceInfo.balance }}</span>
        </van-cell>
      </van-cell-group>
    </van-pull-refresh>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted, inject, ref,reactive,toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import { getCommissionView, getCommissionAmount} from '@/api/income'
import usePage from '@/hooks/usePage'
import to from 'await-to-js';
export default {
  name: 'income',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { navigateTo } = usePage()
    const state = reactive({
      commissionAll:{},
      commissionSub:[],
      commissionList:[],
      balance:[],
      refreshing: false,
      loading: false,
      balanceInfo:{}
    })

    const getAmount = async ()=>{
      let [error,result] = await to(getCommissionAmount())
      if(error) return
      let {code, res} = result;
      if (code == 0) {
          state.balanceInfo = res
        }
    }

    const toPage = url => {
      navigateTo(url)
    }
    const tips = () => {
      Toast('敬请期待')
    }
    const initData = () => {
      state.commissionAll = {}
      state.commissionSub = []
      state.commissionList = []
      state.balance = []
    }
    const getData = async ()=> {
      if (state.refreshing) {
        initData()
        state.refreshing = false
      }
      let [error,result] = await to(getCommissionView())
      _appLoadFanish()
      state.loading = false
      if(error) return;
      let {code, res} = result;
      if (code === 0) {
        state.commissionAll = res.commissionAll
        state.commissionSub = res.commissionSub
        state.commissionList = res.commissionList
        state.balance = res.balance
      }
    }
    //刷新页面
    const onRefresh = () => {
      state.loading = true
      getData()
      getAmount()
    }

    onMounted(async () => {
      getData()
      getAmount()
    })

    function toggleTips(type = 1,index) {
      if (type === 1) {
        state.commissionAll.showTip = !state.commissionAll.showTip
      } else {
        state.commissionList[index].showTip = !state.commissionList[index].showTip
      }
    }

    return {
      ...toRefs(state),
      toPage,
      onRefresh,
      toggleTips
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.picye {
 .biswh('https://x.jzbull.com/dsx-h5/wallet-bg.png', 345px, 188px);
  margin: 12px auto;
  padding-top: 30px;
  position: relative;
  &_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    line-height: 22px;
    .sc(14px,#000,400);
    position: relative;
  }
  &_money {
    font-size: 32px;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
    > span:first-of-type{
      .sc(32px,#000,400);
      margin-top: 4px;
      line-height: 45px;
    }
  }
  &_btn {
    display: flex;
    position: absolute;
    width: 100%;
    height: 75px;
    bottom: 0;
    > div {
      flex: 1;
      text-align: center;
      padding-top: 19px;
      display: flex;
      flex-direction: column;
      span:first-of-type{
        .sc(12px,#000,400)
      }
      span:last-of-type{
        margin-top: 4px;
        .sc(18px,#000,400)
      }
    }
  }
}
.card-box {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 15px ;
}
.cell-it{
  width: 100%;
  position: absolute;
  z-index: 2000;
  display: flex;
  align-items: center;
  >span{
    margin-right: 5px;
  }
}

.popover{
  z-index: 2021;
}
.tips{
  padding: 5px 10px;
}
.wen{
  margin-left: 5px;
}
.van-cell{
  padding: 13px 16px;
}
</style>
